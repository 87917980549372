import React, { useState, useEffect, useContext } from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import DropDown from "../../common/dropDown";
import { AppContext } from "./../../App";
import SpinnerInfinity from "./../../common/spinner";
import BounceLoader from "react-spinners/BounceLoader";
import { ReactComponent as NoDataSVG } from "./../../assets/noDataSVG.svg";
import d from "./../../assets/noData/Web placeholders (Analytics 1).png";
import { createGradientColor, getData } from "../../common/utils";

Chart.register(ChartDataLabels);
Chart.register(...registerables);

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};


function DemographicChart(props) {
  const { gym_id, month, year } = props;

  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { appInfo } = useContext(AppContext);
  const override = {
    top: '7rem',
    left: '0rem'
  };

  useEffect(() => {
    fetchWorkoutData();
    setIsLoading(true);
  }, [gym_id, month, year]);
  const labelMapping = {
    Freshman: "Fresh.",
    Sophmore: "Soph.",
    Junior: "Jun.",
    Senior: "Sen.",
    "Grad student": "Grad.",
    "Faculty/Staff": "Faculty.",
  };

  const newOrder = ["Fresh.", "Soph.", "Jun.", "Sen.", "Grad.", "Faculty."];

  const fetchWorkoutData = async () => {
    try {
      const data = await getData(
        `workouts?month=${month}&year=${year}&gym_id=${gym_id}&facility_type=${appInfo?.org?.facility_type ?? "university"}`,
        appInfo.token
      );
      let defKey = 100;
      const dataDict = data.classLevels.reduce((acc, label, index) => {
        let newLabel = label;
        if (labelMapping.hasOwnProperty(label)) {
          newLabel = labelMapping[label];
        }
        let i = newOrder.indexOf(newLabel);

        if (i == -1) {
          i = defKey;
          defKey += 1;
        }
        acc[newLabel] = {
          key: i,
          value: data.countsArray[index],
        };
        return acc;
      }, {});

      const sortedEntries = Object.entries(dataDict).sort(
        (a, b) => a[1].key - b[1].key
      );

      const labels = sortedEntries.map(([label, { key }]) => label);
      const values = sortedEntries.map(([label, { value }]) => value);
      const maxValue = Math.max(...values);
      const adjustedValues = values.map(value => value === maxValue ? 0 : maxValue - value);
      const processedData = {
        labels: labels,
        datasets: [
          {
            label: "# of Workouts",
            data: values,
            shadowOffsetX: 4,
            shadowOffsetY: 4,
            shadowBlur: 6,
            borderRadius: 10,
            // backgroundColor:"#F4F4F4"
          },
          {
            label: "",
            data: adjustedValues,
            shadowOffsetX: 4,
            shadowOffsetY: 4,
            shadowBlur: 6,
            borderRadius: 10,
            backgroundColor: "#F4F4F4",
            tooltip: false,
            datalabels: {
              formatter: (value) => ""
            }
          },
        ],
      };

      setChartData(processedData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching workouts:", error);
    }
  };

  const optionale = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
        ticks: {
          color: "black",
          font: {
            size: 12,
            style: "italic",
          },
        },
        stacked: true
      },
      y: { display: false, stacked: true },
    },
    // tooltips: {
    //   enabled: true,
    //   mode: 'single',
    //   onlyShowForDatasetIndex: [0],
    //   callbacks: {
    //     label: function(tooltipItems, data) {
    //       return data.datasets[tooltipItems.datasetIndex].label + ': ' + tooltipItems.yLabel + ' %';
    //     }
    //   }
    // },
    tooltips: {
      filter: function (tooltipItem) {
          return tooltipItem.datasetIndex === 0;
      }
  },
    plugins: {
      legend: { display: false },
      // tooltips: { enabled: true,onlyShowForDatasetIndex: [0] },
      datalabels: {
        color: "white",
        anchor: "end",
        align: (context) => {
          const index = context.dataIndex
          const data = context.dataset.data
          const max = Math.max(...data)
          const percentil = 0.1
          const value = data[index]
          return value < max * percentil ? 'top' : 'start'
        },
        font: {
          weight: 'bold',
          size: 16,
        },
        formatter: (value) => value == 0 ? "" : value,
      },
    },
    layout: {
      padding: { top: 10 },
    },
    backgroundColor: (context) => {
      return createGradientColor(context, appInfo);
    },
  };

  return (
    <div className="chartCard grid grid-flow-col grid-rows-5">
      <div className="chartHeader flex justify-between  row-span-1">
        <p className="chartTitle text-gray-600 italic font-semibold">
          Demographic data
        </p>
        <p className="chartTitle text-gray-400 font-light pt-1">
          #Number of workouts
        </p>
      </div>
      <div className="dg-canvas-container h-80 flex justify-center w-[99%] row-span-4">
        {isLoading ? (
          <BounceLoader
            color={appInfo.org.branding_colors[0]}
            loading={isLoading}
            cssOverride={override}
            size={'4rem'}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : chartData != null && chartData.datasets[0].data.length > 0 ? (
          <Bar data={chartData} options={optionale} />
        ) : (
          <div className="flex flex-col" style={{ alignItems: "center" }}>
            <img src={d} alt="logo" className="h-64" />
            <div className="text-gray-300">No data is available</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DemographicChart;

import React from "react";

const Header = ({ title, subtitle,userCount,addressal, titleClass, subTitleClass, subtitleStyle }) => {
  return (
    <div className="header w-[29rem]">
      <div className="splitClientTitle" style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
        <h1 className={titleClass} style={{ flex: '1 1 auto' }}>{title}</h1>
      </div>
      {/* <h5 className={subTitleClass} style={subtitleStyle}>{subtitle}</h5> */}
      <div className="countTag pt-4">

         <b> {userCount} </b> Total {addressal} on Redprint
      </div>
    </div>
  );
};

Header.defaultProps = {
  titleClass: "clientTitle",
  subTitleClass: "clientSubtitle bg-gradient-custom",
};

export default Header;

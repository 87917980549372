import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "../../App";
import { getData } from "../../common/utils";
import placeholderThumbnail from "../../assets/noData/thumbnail_placeholder__square.jpg";

const VideoPlayer = ({ videoKey, thumbnailKey }) => {
  const [videoUrl, setVideoUrl] = useState("");
  const [thumbnailData, setThumbnailData] = useState(null);
  const [showVideo, setShowVideo] = useState(false);
  const { appInfo } = useContext(AppContext);
  const [isThumbnailLoading, setIsThumbnailLoading] = useState(true);

  useEffect(() => {
    const fetchVideoUrl = async () => {
      try {
        let response = await getData(
          `exercise/video/thumbnail/${thumbnailKey}`,
          appInfo.token
        );
        if (response.Imagedata == null) {
          response = await getData(`exercise/video/thumbnail/generate-thumbnail/${videoKey}`,appInfo.token);
        }
        if (response.Imagedata) {
          setThumbnailData(response.Imagedata);
        }
      } catch (error) {
        console.error(`Error fetching thumbnail data for thumbnailKey: ${thumbnailKey} and videoKey: ${videoKey}`,error);
      } finally {
        setIsThumbnailLoading(false); 
      }
    };
    fetchVideoUrl();
  }, [thumbnailKey, appInfo.token, videoKey]);


  const handleThumbnailClick = async () => {
    // Implementation depends on how you plan to show the video
    const response = await getData(`exercise/video/${videoKey}`, appInfo.token);
    setVideoUrl(response.url);
    setShowVideo(true);
  };

  return (
    <div className="rounded-t-md overflow-hidden z-8">
       {isThumbnailLoading ? (
        <img
          src={placeholderThumbnail}
          alt="Thumbnail"
          onClick={handleThumbnailClick}
          className="w-[150px] h-[150px] object-cover"
        />
      ) : (
        <img
          src={`data:image/jpeg;base64,${thumbnailData}`}
          alt="Thumbnail"
          onClick={handleThumbnailClick}
          className="w-full h-full object-cover"
        />
      )}

      {showVideo && videoUrl && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "100",
          }}
        >
          <video
            controls
            style={{
              width: "80%",
              height: "80%",
              zIndex: "10",
              position: "absolute",
            }}
          >
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <button
            onClick={() => setShowVideo(false)}
            style={{
              position: "absolute",
              top: 20,
              right: 20,
              fontSize: "24px",
              color: "white",
            }}
          >
            &times;
          </button>
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;

import React, { useState, useEffect } from "react";

function DropDown({ options, onSelect }) {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
  const [maxOptionLength, setMaxOptionLength] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const maxLength = Math.max(...options.map((option) => option.length));
    const maxWidth = `${maxLength + 1}vw`;
    setMaxOptionLength(maxWidth);
  }, [options]);

  const handleUpArrow = () => {
    setSelectedOptionIndex((prevIndex) => {
      const newIndex = Math.max(prevIndex - 1, 0);
      onSelect && onSelect(newIndex);
      return newIndex;
    });
  };

  const handleDownArrow = () => {
    setSelectedOptionIndex((prevIndex) => {
      const newIndex = Math.min(prevIndex + 1, options.length - 1);
      onSelect && onSelect(newIndex);
      return newIndex;
    });
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className="relative inline-block text-left"
      data-html2canvas-ignore="true"
    >
      <div>
        <button
          type="button"
          className="inline-flex justify-center w-max rounded-md border font-bold border-gray-300 shadow-sm px-2 py-2 bg-white text-sm text-blue-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100"
          aria-expanded={isOpen ? "true" : "false"}
          onClick={toggleDropdown}
        >
          {options[selectedOptionIndex]}
          <svg
            className="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M10 12a1 1 0 01-.707-.293l-4-4a1 1 0 011.414-1.414L10 9.586l3.293-3.293a1 1 0 111.414 1.414l-4 4A1 1 0 0110 12z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      {isOpen && (
        <div
          className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div className="py-1" role="none">
            {options.map((option, index) => (
              <button
                key={index}
                className={`block w-full text-left px-4 py-2 text-sm ${
                  selectedOptionIndex === index
                    ? "font-semibold text-blue-600"
                    : "font-normal text-gray-700"
                } hover:bg-gray-100 hover:text-gray-900`}
                onClick={() => {
                  setSelectedOptionIndex(index);
                  onSelect && onSelect(index);
                  toggleDropdown();
                }}
              >
                {option}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default DropDown;

import React from 'react';
import { numberWithCommas } from '../../common/utils';

function StatBox({
  count, unit, subtext, progressCount, progress,
}) {
  return (
    <div className="header text-ellipsis">
      <span className="flex items-center">
        <h2 className="title font-black text-3xl mr-2" >
          +
          {numberWithCommas(count)}
        </h2>
        <h6 className="statUnit text-base text-gray-600">{unit}</h6>
      </span>
      <h5 className="text-base font-bold">{subtext}</h5>
      <div className="progress mt-2 p-4 w-max text-sm h-6 flex items-center justify-center text-center text-ellipsis rounded-lg bg-white text-black">
        <b>{progressCount}</b>&nbsp;{progress}
      </div>
    </div>
  );
}

export default StatBox;

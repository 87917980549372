import React, { useState, useEffect, useContext } from "react";
import DropDown from "../../common/dropDown";
import "chart.js/auto";
import { Radar } from "react-chartjs-2";
import { AppContext } from "./../../App";
import { createGradientColor, getData } from "../../common/utils";
import SpinnerInfinity from "./../../common/spinner";
import BounceLoader from "react-spinners/BounceLoader";
import { ReactComponent as NoDataSVG } from "./../../assets/noDataSVG.svg";
import t from "./../../assets/noData/Web placeholders (Analytics 3).png";

const exerciseMuscleDict = JSON.parse(localStorage.getItem("exercise_data"));

const options = ["Reps", "Sets", "Volume"];
const   override = { top: '7rem',
    left: '8rem'} ;

function MuscleGroupChart(props) {
  const { gym_id, month, year } = props;

  const [chartData, setChartData] = useState(null);
  const [chartOptions, setChartOptions] = useState(null);
  const [groupData, setGroupData] = useState(null);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [isLoading, setIsLoading] = useState(true);
  const { appInfo } = useContext(AppContext);

  useEffect(() => {
    setGroupData(null);
    setIsLoading(true);
  }, [gym_id, month, year]);

  useEffect(() => {
    fetchData();
  }, [selectedOption, groupData]);

  const handleDropDownChange = (sO) => {
    setSelectedOption(options[sO]);
  };

  const fetchData = async () => {
    try {
      let transformedResult = null;

      if (groupData == null) {
        const data1 = await getData(
          `usage?month=${month}&year=${year}&gym_id=${gym_id}`,
          appInfo.token
        );

        const { result } = data1;
          
          const distinctMuscleGroups = [...new Set(exerciseMuscleDict.map(ex => ex.muscleGroup))];
          
          const defaultResults = distinctMuscleGroups.map(muscleGroup => ({
              MuscleGroup: muscleGroup,
              reps: 0,
              sets: 0,
              volume: 0
          }));
      
          const mappedResults = result.map((item) => {
              const exercise = exerciseMuscleDict.find(ex => ex.exercise_identifier === item.engine_exercise_name);
              return {
                  MuscleGroup: exercise ? exercise.muscleGroup : 'unknown',
                  reps: item.reps,
                  sets: item.sets,
                  volume: item.volume,
              };
          });
          if (mappedResults.length>0)
          {
            transformedResult = [...defaultResults, ...mappedResults]
          }
          else{
            transformedResult = [...mappedResults]
          }

          transformedResult = transformedResult.reduce((acc, current) => {
              const existing = acc.find(item => item.MuscleGroup === current.MuscleGroup);
              if (existing) {
                  existing.reps += current.reps;
                  existing.sets += current.sets;
                  existing.volume += current.volume;
              } else {
                  acc.push(current);
              }
              return acc;
          }, []);
      
          setGroupData(transformedResult);
      } else {
          transformedResult = groupData;
      }

      const groupedResult = transformedResult.reduce((groups, item) => {
        const key = item.MuscleGroup;
        if (!groups[key]) {
          groups[key] = 0;
        }
        groups[key] += parseInt(item[selectedOption.toLowerCase()]);
        return groups;
      }, {});

      const labels = Object.keys(groupedResult);
      const data = Object.values(groupedResult);
      const processedData = {
        labels,
        datasets: [
          {
            label: "Muscle Development",
            data,
            backgroundColor: (context) => {
              //return createGradientColor(context, appInfo, labels.length);
              return appInfo.org.branding_colors[1];
            },
            borderColor: appInfo.org.branding_colors[1],
            borderWidth: 1,
            pointRadius: 0,
            radius: 6,
          },
        ],
      };
      const chartOptions = {
        responsive: true,
        radius: 6,

        plugins: {
          id: "custom_labels",
          datalabels: {
            color: "black",
            font: {
              size: 12,
              weight: "bold",
            },
            formatter: function (value, context) {
              return context.chart.data.datasets[0].labels;
            },
          },
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        layout: {
          padding: {
            bottom: 120,
            top: 10,
            left: 35,
            right: 50
          },
        },
        scales: {
          r: {
            grid: {
              color: ["#DFDFDF", "#DFDFDF", "#555555"],
            },
            ticks: {
              display: false,
              backdropColor: appInfo.org.branding_colors[1],
              beginAtZero: true,
              max: 5,
              min: 0,
              stepSize: (Math.max(...data) - Math.min(...data)) / 2,
            },
          },
        },
      };
      setChartData(processedData);
      setChartOptions(chartOptions);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching workouts:", error);
    }
  };

  return (
    <div className="grid grid-flow-col grid-rows-8">
        <div className="z-0 chartHeader flex justify-between row-span-1 w-[28vw]">
        <p className="chartTitle text-gray-600 italic font-semibold">
          Muscle Group
        </p>
        <DropDown options={options} onSelect={handleDropDownChange} />
        </div>
    <div className="chartCard  grid place-items-center row-span-7">

      <div className="radar-container h-80 w-10/12 flex justify-center">
        {isLoading ? (
          <BounceLoader
          color={`#${appInfo?.org?.branding_colors[0] ?? "#FC5A02"}`}          loading={isLoading}
          cssOverride={override}
          size={'4rem'}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
        ) : groupData != null && groupData.length > 0 ? (
          <Radar data={chartData} options={chartOptions} />
        ) : (
          <div className="flex flex-col" style={{ alignItems: "center" }}>
            <img src={t} alt="logo" className="h-64" />
            <div className="text-gray-300">No data available</div>
          </div>
        )}
      </div>
    </div>
    </div>
  );
}

export default MuscleGroupChart;
